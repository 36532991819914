<template>
  <div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <div>
                    <h5>
                    الملاحظات عند دخول الطلاب
                    <br>
                </h5>
                <p>تظهر هنا الملاحظات الموجودة على الطلاب وقام الطالب بالحضور للمدرسة بعد اضافة الملاحظة.</p>
                </div>
            </div>
            <div class="card-body">
                <div class="col-12 table-responsive">
                    <table class="table table-hover table-bordered table-sm">
                        <thead>
                            <th>
                                الطالب
                            </th>
                            <th>
                                الملاحظة
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="note in notes" :key="note._id">
                                <td>
                                    {{ student(note.number).name }}
                                </td>
                                <td>
                                    {{ note.content }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            students: {},
            notes: []
        }
    },
    created(){
        var g = this;
        $.post(api + '/user/students/list', {
            jwt: this.user.jwt,
            page: 1,
            perpage: 10000
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                r.response.forEach(function(a){
                    g.students[a.number] = a;
                })
                g.getNotes()
            }
        }).fail(function(){
            g.loading = false;
        })
    },
    methods: {
        getNotes(){
            var g = this;
            $.post(api + '/user/students/notes/with-report', {
                jwt: this.user.jwt
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.notes = r.response;
                }
            }).fail(function(){
                g.loading = false;
            })
        },
        student(number){
            if(this.students[number]){
                return this.students[number];
            }else{
                return {};
            }
        }
    }
}
</script>

<style>

</style>